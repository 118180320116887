import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Brand from './pages/Brand';
import BlogPage from './pages/ProductList';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoryList from './pages/CategoryList';
import Roles from './pages/Roles';
import CaseStudy from './pages/CaseStudy';
import About from './pages/About';
import Careers from './pages/Careers';
import CaseStudyList from './pages/CaseStudyList';
import CareerList from './pages/CareerList';
import ViewResponses from './pages/ViewResponses';
import QuotationDetailsPage from './pages/QuotationDetailsPage';
import CustomerListPage from './pages/CustomerListPage';
import Solutions from './pages/Solutions';
import SolutionsList from './pages/SolutionsList';
import AddRole from './pages/AddRole';
import SignupView from './pages/Signup';
import BrandList from './pages/BrandList';
import Protected from './pages/Protected';
import Category from './pages/Category';
import Unauthorized from './pages/Unauthorized';
import AddUser from './pages/AddUser';
import ContactList from './pages/ContactList';
// ----------------------------------------------------------------------

export default function Router() {
  const userData = JSON.parse(localStorage.getItem('login'));
  const role = userData ? userData.role : undefined;
  // console.log("This is user Role",role);
  // console.log("This is UserData",userData);

  const hasAccessPage1 = userData && userData.user_role_permissions && userData.user_role_permissions[0] === '1';
  const hasAccessPage2 = userData && userData.user_role_permissions && userData.user_role_permissions[1*4] === '1';
  const hasAccessPage3 = userData && userData.user_role_permissions && userData.user_role_permissions[2*4] === '1';
  const hasAccessPage4 = userData && userData.user_role_permissions && userData.user_role_permissions[3*4] === '1';
  const hasAccessPage5 = userData && userData.user_role_permissions && userData.user_role_permissions[4*4] === '1';
  const hasAccessPage6 = userData && userData.user_role_permissions && userData.user_role_permissions[5*4] === '1';
  const hasAccessPage7 = userData && userData.user_role_permissions && userData.user_role_permissions[6*4] === '1';
  const hasAccessPage8 = userData && userData.user_role_permissions && userData.user_role_permissions[7*4] === '1';
  const hasAccessPage9 =userData && userData.user_role_permissions && userData.user_role_permissions[8*4]==='1';
  const hasAccessPage10 =userData && userData.user_role_permissions && userData.user_role_permissions[9*4]==='1';
  const hasAccessPage11 =userData && userData.user_role_permissions && userData.user_role_permissions[10*4]==='1';
  const hasAccessPage12 =userData && userData.user_role_permissions && userData.user_role_permissions[11*4]==='1';
  const hasAccessPage13 =userData && userData.user_role_permissions && userData.user_role_permissions[12*4]==='1';
  
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <Protected Component={DashboardAppPage} /> },
        { path: 'user', element: hasAccessPage1 ? <Protected Component={UserPage} /> : <Unauthorized/> },
        { path: 'roles', element: hasAccessPage2 ? <Protected Component={Roles} /> : <Unauthorized/> },
        { path: 'caseStudyList', element:hasAccessPage3? <Protected Component={CaseStudyList} />:<Unauthorized/> },
        { path: 'list', element: hasAccessPage4?<Protected Component={BlogPage} />:<Unauthorized/> },
        { path: 'category', element: hasAccessPage5?<Protected Component={CategoryList} />:<Unauthorized/> },
        { path: 'customerList', element:hasAccessPage6? <Protected Component={CustomerListPage} />:<Unauthorized/> },
        { path: 'brand', element: hasAccessPage7?<Protected Component={BrandList} />:<Unauthorized/> },
        { path: 'solutionsList', element: hasAccessPage8?<Protected Component={SolutionsList}/>:<Unauthorized/>  },
        { path: 'about', element: hasAccessPage9?<Protected Component={About} />:<Unauthorized/> },
        { path: 'careerList', element: hasAccessPage10?<Protected Component={CareerList} />:<Unauthorized/> },
        { path: 'QuotationDetailsPage', element:hasAccessPage11? <Protected Component={QuotationDetailsPage} />:<Unauthorized/> },
        { path: 'viewResponses', element:hasAccessPage12? <Protected Component={ViewResponses} />:<Unauthorized/> },
        { path: 'contact', element: hasAccessPage13?<Protected Component={ContactList} />:<Unauthorized/> },

        { path: 'products', element: <Protected Component={ProductsPage} />},
        { path: 'AddCategory', element: <Protected Component={Category} /> },
        { path: 'addRole', element: <Protected Component={AddRole} /> },
        { path: 'caseStudy', element: <Protected Component={CaseStudy} /> },
        { path: 'careers', element: <Protected Component={Careers} /> },
        { path: 'solutions', element: <Protected Component={Solutions} /> },
        { path: 'AddBrand', element: <Protected Component={Brand} /> },
        {path:'AddUser',element:<Protected Component={AddUser} />},
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: 'login', element: <LoginPage /> },
        // { path: 'signup', element: <SignupView /> },
        { path: '404', element: <Page404 /> },
        { path: 'Unauthorized', element: <Unauthorized /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
