import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Box,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Alert,
  MenuItem,
} from '@mui/material';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import Select from 'react-select';

const AddUser = () => {
  const userData = JSON.parse(localStorage.getItem('login'));
  const user = userData.title;
  const [adduser, setadduser] = useState({
    title: '',
    username: '',
    password: '',
    designation: '',
    status: '',
    role: '',
    id: '',
    CreatedBy: user,
  });

  const handleRoleChange = (event) => {
    const selected = event.target.value;
    setadduser((prevadduser) => ({
      ...prevadduser,
      role: selected,
    }));
  };

  //   function handleFormRoleChange(event, data, clickedRowData = {}) {
  //     const { name, value } = event?.target || {}; // Destructure event.target properties
  //     console.log("This is value", value); // Correctly logging the value

  //     // Validate input to ensure a valid role is selected
  //     const selectedRole = data.find((role) => role.title === value);
  //     if (!selectedRole) {
  //       console.warn(`Invalid role selected: ${value}`);
  //       return; // Prevent invalid role selection
  //     }

  //     // Perform role change logic based on your requirements
  //     // This could involve updating state, making API calls,
  //     // triggering other actions, etc.

  //     // Example: Update local state
  //     const updatedClickedRowData = {
  //       ...clickedRowData,
  //       [name]: value, // Update the "role" property
  //     };

  //     // Handle state updates, API calls, or other actions here
  //     // based on your specific use case

  //     // Inform the user about the successful role change (optional)
  //     console.log(`Role changed to: ${value}`);
  // }

  const [data, setData] = useState([]);

  const [errors, setErrors] = useState({});

  const editor = useRef(null);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setadduser((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const clickedRowData = 0;

  const storedRowData = JSON.parse(localStorage.getItem('adduserrow'));

  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : adduser.title,
    status: storedRowData ? storedRowData.status : adduser.status,
    id: storedRowData ? storedRowData.id : 0,
    username: storedRowData ? storedRowData.username : adduser.username,
    designation: storedRowData ? storedRowData.designation : adduser.designation,
  });

  const handleEditorChange = (name, content) => {
    setadduser({
      ...adduser,
      [name]: content,
    });
  };

  useEffect(() => {
    // Fetch data when the component mounts
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/roles/getactiveroles')
      .then((res) => {
        // Set the response data in the state
        // console.log('This is api data', res.data.response);
        setData(...data, res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  const Submit = () => {
    

    // Initialize newErrors object
    const newErrors = {};

    if (!editMode) {
      if (!adduser.title) newErrors.title = 'Title is a required field!';

      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        // console.log('Add User', adduser);
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/users/portal_add_user', adduser)
          .then((res) => {
            if (res.data.status === true) {
              setHandleSubmit(true);
              setTimeout(() => {
                setHandleSubmit(false);
              }, 5000);
              // console.log(adduser);
            } else {
              alert("Error while  adding user");
              // console.log(res.data);
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setadduser({
          username: '',
          title: '',
          password: '',
          phone: '',
          designation: '',
          role: '',
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | adduser</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New User
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, sub-title ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Username
            </Typography>
            <TextField
              variant="outlined"
              label="Username"
              name="username"
              value={editMode ? RowData.username : adduser.username}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Email
            </Typography>
            <TextField
              variant="outlined"
              label="Email"
              name="title"
              value={editMode ? RowData.email : adduser.email}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}
            <TextField
              variant="outlined"
              label="Password"
              name="password"
              value={editMode ? RowData.password : adduser.password}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Phone
            </Typography>
            <TextField
              variant="outlined"
              label="Phone"
              name="phone"
              value={editMode ? RowData.phone : adduser.phone}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            {errors.summary && <p style={{ color: '#e3342f' }}>{errors.summary}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Designation
            </Typography>
            <TextField
              variant="outlined"
              label="Designation"
              name="designation"
              value={editMode ? RowData.designation : adduser.designation}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Select Role
            </Typography>
            <TextField
              id="outlined-select-category"
              select
              label="Select"
              // defaultValue={editMode ? RowData.category : 'Other'}
              onChange={handleRoleChange}
              helperText="Please select user role"
            >
              {data.map((category) => (
                <MenuItem key={category.title} value={category.title}>
                  {category.title}
                </MenuItem>
              ))}
            </TextField>
            {/* <Select
              variant="outlined"
              label="Role"
              name="role"
              options={data.map((role) => ({ value: role.title, label: role.title }))}
              value={AddUser.role}
              onChange={(event) => handleFormRoleChange(event, data, clickedRowData)}
              placeholder="Select a role"
            /> */}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            Create
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default AddUser;
