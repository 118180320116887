import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Icon,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// mock
import account from '../../../_mock/account';
import logo from './logo.png';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
// import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const [openSubmenus, setOpenSubmenus] = useState({});

  const toggleSubmenu = (title) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [title]: !prevOpenSubmenus[title],
    }));
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 10, py: 4, display: 'inline-flex' }} onClick={onCloseNav}>
        <img
          src={logo}
          width={100}
          height={80}
          alt="LOGO"
        />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.email}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />
      <Box sx={{ ml: 2 }}>
        <List>
          {navConfig.map((item) => (
            <List key={item.title}>
              <ListItemButton onClick={() => toggleSubmenu(item.title)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}>
                    {item.icon} 
                    <span style={{ marginLeft: '8px', flexGrow: 1 }}>
                      {item.submenu ? (
                        <span>{item.title}</span>
                      ) : (
                        <a href={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {item.title}
                        </a>
                      )}
                    </span>
                    {item.submenu && (
                      <ListItemIcon style={{ marginLeft: 'auto', marginBottom: '2px' }}>
                        {openSubmenus[item.title] ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    )}
                  </Typography>
                </div>
              </ListItemButton>
              {openSubmenus[item.title] && item.submenu && (
                <ul style={{ listStyleType: 'disc' }}>
                  {item.submenu.map((submenuItem) => (
                    <ListItem key={submenuItem.title}>
                      <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                      <a href={submenuItem.path}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {submenuItem.title}
                        </Typography>
                      </a>
                    </ListItem>
                  ))}
                </ul>
              )}
            </List>
          ))}
        </List>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
