import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Stack, TextField, Button, Alert } from '@mui/material';
import JoditEditor from 'jodit-react';

const About = () => {

  const navigate = useNavigate();

  const loginData = JSON.parse(localStorage.getItem('login'));

  const createPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[8 * 4 + 1] === '1';

  const editPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[8 * 4 + 2] === '1';

  const deletePermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[8 * 4 + 3] === '1';

  const Unauthorized = () => {
    navigate('/Unauthorized');
  };

  const editor = useRef(null);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const [errors, setErrors] = useState({});

  const [test, setTest] = useState([]);

  const [about, setAbout] = useState({
    title: '',
    des1: '',
    des2: '',
    des3: '',
    des4: '',
    des5: '',
    des6: '',
  });

  useEffect(() => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/about/getabout')
      .then((res) => {
        setTest(...test, res.data.response[0]);
        if (res.data.status === true) {
          const aboutData = res.data.response[0];
          setAbout({
            title: aboutData.title,
            des1: aboutData.des1,
            des2: aboutData.des2,
            des3: aboutData.des3,
            des4: aboutData.des4,
            des5: aboutData.des5,
            des6: aboutData.des6,
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   console.log(test);
  //   console.log(about);
  // }, [test]);

  const handleEditorChange = (name, content) => {
    setAbout({
      ...about,
      [name]: content,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAbout({
      ...about,
      [name]: value,
    });
  };

  const Submit = () => {
    const newErrors = {};
    // console.log('submitted');

    if (!about.title) {
      newErrors.title = 'Title is a required field!';
    }

    if (!about.des1) {
      newErrors.des1 = 'This is a required field!';
    } else if (about.des1.length > 1500) {
      newErrors.des1 = 'Input is greater than 1500 characters limit';
    }

    if (!about.des2) {
      newErrors.des2 = 'This is a required field!';
    } else if (about.des2.length > 1500) {
      newErrors.des2 = 'Input is greater than 1500 characters limit';
    }

    if (!about.des3) {
      newErrors.des3 = 'This is a required field!';
    } else if (about.des3.length > 1500) {
      newErrors.des3 = 'Input is greater than 1500 characters limit';
    }

    if (!about.des4) {
      newErrors.des4 = 'This is a required field!';
    } else if (about.des4.length > 1500) {
      newErrors.des4 = 'Input is greater than 1500 characters limit';
    }

    if (!about.des5) {
      newErrors.des5 = 'This is a required field!';
    } else if (about.des5.length > 1500) {
      newErrors.des5 = 'Input is greater than 1500 characters limit';
    }

    if (!about.des6) {
      newErrors.des6 = 'This is a required field!';
    } else if (about.des6.length > 1500) {
      newErrors.des6 = 'Input is greater than 1500 characters limit';
    }

    setErrors(newErrors);
    // console.log(newErrors);

    if (Object.keys(newErrors).length === 0) {
      axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/about/portaleditabout', about).then((res) => {
        if (res.data.status === true) {
          setHandleSubmit(true);
          setTimeout(() => {
            setHandleSubmit(false);
          }, 5000);
        } else {
          alert("Error");
          // console.log(res.data);
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: About | Minimal UI</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          About Page
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Add FullStop(.) to display data on new Line
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="About Title"
              name="title"
              value={about.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="des1"
              value={about.des1}
              onChange={(newContent) => handleEditorChange('des1', newContent)}
            />
            {errors.des1 && <p style={{ color: '#e3342f' }}>{errors.des1}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              RajKamal Positioning Statement
            </Typography>
            <JoditEditor
              ref={editor}
              name="des2"
              value={about.des2}
              onChange={(newContent) => handleEditorChange('des2', newContent)}
            />
            {errors.des2 && <p style={{ color: '#e3342f' }}>{errors.des2}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Key Achievements: Rajkamal's Major Milestones Over the Years
            </Typography>
            <JoditEditor
              ref={editor}
              name="des3"
              value={about.des3}
              onChange={(newContent) => handleEditorChange('des3', newContent)}
            />
            {errors.des3 && <p style={{ color: '#e3342f' }}>{errors.des3}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Why Rajkamal BarScan Systems?
            </Typography>
            <JoditEditor
              ref={editor}
              name="des4"
              value={about.des4}
              onChange={(newContent) => handleEditorChange('des4', newContent)}
            />
            {errors.des4 && <p style={{ color: '#e3342f' }}>{errors.des4}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Our Presence
            </Typography>
            <JoditEditor
              ref={editor}
              name="des5"
              value={about.des5}
              onChange={(newContent) => handleEditorChange('des5', newContent)}
            />
            {errors.des5 && <p style={{ color: '#e3342f' }}>{errors.des5}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Company Expertise
            </Typography>
            <JoditEditor
              ref={editor}
              name="des6"
              value={about.des6}
              onChange={(newContent) => handleEditorChange('des6', newContent)}
            />
            {errors.des6 && <p style={{ color: '#e3342f' }}>{errors.des6}</p>}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={editPermissions?Submit:Unauthorized}
          >
            {test ? 'Edit' : 'Create'}
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              Data Posted Successfully !
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default About;
