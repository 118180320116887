// ----------------------------------------------------------------------
const userData=JSON.parse(localStorage.getItem('login'));
// const account = {
//   displayName: 'Jaydon Frankie',
//   email: 'demo@.cc',
//   photoURL: '/assets/images/avatars/avatar_default.jpg',
// };

const account = {
  displayName: userData?userData.role:null,
  email:userData? userData.title:null,
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
