import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { Container, Stack, Typography, Box, TextField, Button, Switch, FormControlLabel, Alert } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export default function ProductsPage() {
  const [categoryData, setcategoryData] = useState([]);

  const [brandData, setBrandData] = useState([]);

  const [userdata, setUserdata] = useState({
    title: '',
    img: '',
    product_description: '',
    col1_title: '',
    col2_title: '',
    col3_title: '',
    col1_description: '',
    col2_description: '',
    col3_description: '',
    features: '',
    product_sku: '',
    product_code: '',
    cost_price: '',
    selling_price: '',
    category: '',
    product_currency: 'INR',
    tax_percentage: '0.00',
    brand: '',
  });

  const [errors, setErrors] = useState({});
  const [toogle, setToogle] = useState(false);

  const storedRowData = JSON.parse(localStorage.getItem('row'));
  const [editMode, setEditMode] = useState(!!storedRowData);

  /* eslint-disable camelcase */
  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : userdata.title,
    img: storedRowData ? storedRowData.img : userdata.img,
    product_description: storedRowData ? storedRowData.product_description : userdata.product_description,
    col1_title: storedRowData ? storedRowData.col1_title : userdata.col1_title,
    col2_title: storedRowData ? storedRowData.col2_title : userdata.col2_title,
    col3_title: storedRowData ? storedRowData.col3_title : userdata.col3_title,
    col1_description: storedRowData ? storedRowData.col1_description : userdata.col1_description,
    col2_description: storedRowData ? storedRowData.col2_description : userdata.col2_description,
    col3_description: storedRowData ? storedRowData.col3_description : userdata.col3_description,
    features: storedRowData ? storedRowData.features : userdata.features,
    product_sku: storedRowData ? storedRowData.product_sku : userdata.product_sku,
    product_code: storedRowData ? storedRowData.product_code : userdata.product_code,
    cost_price: storedRowData ? storedRowData.cost_price : userdata.cost_price,
    selling_price: storedRowData ? storedRowData.selling_price : userdata.selling_price,
    category: storedRowData ? storedRowData.category : userdata.category,
    product_currency: storedRowData ? storedRowData.product_currency : userdata.product_currency,
    tax_percentage: storedRowData ? storedRowData.tax_percentage : userdata.tax_percentage,
    status: storedRowData ? storedRowData.status : userdata.status,
    id: storedRowData ? storedRowData.id : null,
    brand: storedRowData ? storedRowData.brand : userdata.brand,
  });

  const [handleSubmit, setHandleSubmit] = useState(false);

  const editor = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setUserdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const contentFieldChanged = (property, newContent) => {
    setUserdata((prevUserData) => ({
      ...prevUserData,
      [property]: newContent,
    }));
  };

  // const currencies = [
  //   { value: 'USD', label: '$' },
  //   { value: 'EUR', label: '€' },
  //   { value: 'INR', label: '₹' },
  // ];

  const currencies = JSON.parse(localStorage.getItem('product_currency'));
  // console.log('These are all the currencies', currencies);

  // const categories = [
  //   { value: 'Printer', label: 'Printer' },
  //   { value: 'Hardware', label: 'Hardware' },
  //   { value: 'Consumables', label: 'Consumables' },
  //   { value: 'Rfid', label: 'Rfid' },
  //   { value: 'Scanner', label: 'Scanner' },
  //   { value: 'Other', label: 'Other' },
  // ];

  const categories = JSON.parse(localStorage.getItem('categories'));
  // console.log('this are category', categories);
  // const brands = [
  //   { value: 'Printer', label: 'Printer' },
  //   { value: 'Hardware', label: 'Hardware' },
  //   { value: 'Consumables', label: 'Consumables' },
  //   { value: 'Rfid', label: 'Rfid' },
  //   { value: 'Scanner', label: 'Scanner' },
  //   { value: 'Other', label: 'Other' },
  // ];

  const brands = JSON.parse(localStorage.getItem('brands'));
  // console.log('These are brands', brands);

  const handleSwitchChange = () => setToogle(!toogle);

  const handleCategoryChange = (event) => {
    const selected = event.target.value;
    setUserdata((prevUserData) => ({
      ...prevUserData,
      category: selected,
    }));
  };

  const handleBrandChange = (event) => {
    const selected = event.target.value;
    setUserdata((prevUserData) => ({
      ...prevUserData,
      brand: selected,
    }));
  };

  const handleproduct_currencyChange = (event) => {
    const selected = event.target.value;
    setUserdata((prevUserData) => ({
      ...prevUserData,
      product_currency: selected,
    }));
  };

  // console.log('This is RowData', RowData);

  const Submit = async () => {
    // console.log(userdata)
    // title: '',
    // img:'',
    // description: '',
    // col1_title: '',
    // col2_title: '',
    // col3_title: '',
    // col1_description: '',
    // col2_description: '',
    // col3_description: '',
    // features:'',
    // product_sku: '',
    // product_code: '',
    // cost_price: '',
    // selling_price: '',
    // category: 'Other',
    // product_currency: 'INR',
    // tax_percentage: '0.00',
    const newErrors = {};

    const imgPattern = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    const numberPattern = /^[0-9]+$/;

    if (!userdata.title) newErrors.title = 'Title is a required field!';
    if (!userdata.description) newErrors.description = 'Description is a required feild!';
    if (!userdata.img) {
      newErrors.img = 'img is a required field !';
    } else if (!imgPattern.test(userdata.img)) {
      newErrors.img = 'img Link format Invalid';
    }
    if (!userdata.col1_title) newErrors.col1_title = 'Col1 Title  is a required field!';
    if (!userdata.col2_title) newErrors.col2_title = 'Col2 Title  is a required field!';
    if (!userdata.col3_title) newErrors.col3_title = 'Col3 Title is a required feild!';
    if (!userdata.col1_description) newErrors.col1_description = 'Col1 Description is a required field!';
    if (!userdata.col2_description) newErrors.col2_description = 'Col2 Description  is a required field!';
    if (!userdata.col3_description) newErrors.col3_description = 'Col3 description  is a required field!';
    if (!userdata.description) newErrors.description = 'Product Features is a required field!';
    if (!userdata.features) newErrors.features = 'Product Features  is a required field!';
    if (!userdata.product_code) newErrors.product_code = 'Product product_code  is a required field!';
    if (!userdata.product_sku) newErrors.product_sku = 'Product product_sku product_code  is a required field!';
    if (!userdata.cost_price) newErrors.cost_price = 'Product Price  is a required field!';
    else if (!numberPattern.test(userdata.cost_price)) {
      newErrors.cost_price = 'Price should be numeric!';
    }
    if (!userdata.selling_price) newErrors.selling_price = 'Product SellingPrice  is required field!';
    else if (!numberPattern.test(userdata.selling_price)) {
      newErrors.selling_price = 'Price should be numeric!';
    }
    setErrors(newErrors);
    try {
      if (Object.keys(newErrors).length === 0) {
        if (editMode) {
          const res = await axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portaleditproductdetails', RowData);
          // console.log('This is Row Data', RowData);
          // console.log('This is userdata', userdata);
          if (res.data.status === true) {
            alert('Product Data Updated Successfully');
            localStorage.removeItem('row');
            // setRowdata({
            //   title: '',
            //   img: '',
            //   product_description: '',
            //   col1_title: '',
            //   col2_title: '',
            //   col3_title: '',
            //   col1_description: '',
            //   col2_description: '',
            //   col3_description: '',
            //   product_sku: '',
            //   features: '',
            //   product_code: '',
            //   cost_price: '',
            //   selling_price: '',
            //   category: 'Other',
            //   product_currency: 'INR',
            //   tax_percentage: '0.00',
            // });
          }

          setHandleSubmit(true);
          setTimeout(() => {
            setHandleSubmit(false);
          }, 5000);

          setUserdata({
            title: '',
            product_description: '',
            col1_title: '',
            col2_title: '',
            col3_title: '',
            col1_description: '',
            col2_description: '',
            col3_description: '',
            product_sku: '',
            product_code: '',
            cost_price: '',
            selling_price: '',
            category: 'Other',
            product_currency: 'INR',
            tax_percentage: '0.00',
            brand: '',
          });
        } else {
          // console.log('this is data in submit', userdata);
          const res = await axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portaladdproduct', userdata);

          if (res.data.status === true) {
            setUserdata({
              title: '',
              img: '',
              product_description: '',
              col1_title: '',
              col2_title: '',
              col3_title: '',
              col1_description: '',
              col2_description: '',
              col3_description: '',
              features: '',
              product_sku: '',
              product_code: '',
              cost_price: '',
              selling_price: '',
              category: 'Other',
              product_currency: 'INR',
              tax_percentage: '0.00',
              brand: '',
            });
          }

          setHandleSubmit(true);
          setTimeout(() => {
            setHandleSubmit(false);
          }, 5000);
        }
      } else {
        alert('Please Fill All Fields');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/getcategory')
      .then((res) => {
        // Set the response data in the state
        setcategoryData(...categoryData, res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Fetch data when the component mounts
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/products/getbrands')
      .then((res) => {
        // Set the response data in the state
        setBrandData(...brandData, res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   console.log('This is Category data', categoryData);
  //   console.log('This is Brand Data', brandData);
  // }, [categoryData, brandData]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Products | Minimal UI</title>
      </Helmet>

      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Product
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, img ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="Product Title"
              name="title"
              value={editMode ? RowData.title : userdata.title}
              onChange={handleInputChange}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Product Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="product_description"
              value={editMode ? RowData.product_description : userdata.product_description}
              onChange={(newContent) => contentFieldChanged('product_description', newContent)}
            />
            {errors.product_description && <p style={{ color: '#e3342f' }}>{errors.product_description}</p>}
            <Typography variant="body1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add img Link
            </Typography>
            <TextField
              variant="outlined"
              label="img Link"
              name="img"
              value={editMode ? RowData.img : userdata.img}
              onChange={handleInputChange}
            />
            {errors.img && <p style={{ color: '#e3342f' }}>{errors.img}</p>}
            {/* <FileUploader
              multiple
              handleChange={handleChange}
              name="file"
              types={['JPEG', 'PNG', 'GIF']}
              style={{ width: '500px', height: '200px' }}
            />
            <p>{file ? `File name: ${file[0].name}` : 'No files uploaded yet'}</p> */}
          </Stack>
        </Box>

        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Properties
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 3 }}>
          Additional functions and attributes ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="Product Code"
              name="product_code"
              value={editMode ? RowData.product_code : userdata.product_code}
              onChange={handleInputChange}
            />
            {errors.product_code && <p style={{ color: '#e3342f' }}>{errors.product_code}</p>}
            <TextField
              variant="outlined"
              label="Product product_sku"
              name="product_sku"
              value={editMode ? RowData.product_sku : userdata.product_sku}
              onChange={handleInputChange}
            />
            {errors.product_sku && <p style={{ color: '#e3342f' }}>{errors.product_sku}</p>}
            <TextField
              id="outlined-select-category"
              select
              label="Select"
              defaultValue={editMode ? RowData.category : 'Other'}
              onChange={handleCategoryChange}
              helperText="Please select product category"
            >
              {categoryData.map((category) => (
                <MenuItem key={category.title} value={category.title}>
                  {category.title}
                </MenuItem>
              ))}
            </TextField>
            {errors.category && <p style={{ color: '#e3342f' }}>{errors.category}</p>}
            {/* {console.log('this is category data', RowData.category, userdata.category)} */}
            {/* <TextField
              variant="outlined"
              label="Brand Code"
              name="col3_title"
              value={editMode ? RowData.brand : userdata.brand}
              onChange={handleInputChange}
              helperText="Same as brand List"
            /> */}
            <TextField
              id="outlined-select-category"
              select
              label="Select"
              defaultValue={editMode ? RowData.title : 'Other'}
              onChange={handleBrandChange}
              helperText="Please select product Brand"
            >
              {brandData.map((item) => (
                <MenuItem key={item.title} value={item.title}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
            {errors.brand && <p style={{ color: '#e3342f' }}>{errors.col3_title}</p>}
          </Stack>
        </Box>

        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Product Tab Info
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Description,Headers,....
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="Column Header 1"
              name="col1_title"
              value={editMode ? RowData.col1_title : userdata.col1_title}
              onChange={handleInputChange}
            />
            {errors.col1_title && <p style={{ color: '#e3342f' }}>{errors.col1_title}</p>}
            <TextField
              variant="outlined"
              label="Column Header 2"
              name="col2_title"
              value={editMode ? RowData.col2_title : userdata.col2_title}
              onChange={handleInputChange}
            />
            {errors.col2_title && <p style={{ color: '#e3342f' }}>{errors.col2_title}</p>}
            <TextField
              variant="outlined"
              label="Column Header 3"
              name="col3_title"
              value={editMode ? RowData.col3_title : userdata.col3_title}
              onChange={handleInputChange}
            />
            {errors.col3_title && <p style={{ color: '#e3342f' }}>{errors.col3_title}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add column 1 Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="col1_description"
              value={editMode ? RowData.col1_description : userdata.col1_description}
              onChange={(newContent) => contentFieldChanged('col1_description', newContent)}
            />
            {errors.col1_description && <p style={{ color: '#e3342f' }}>{errors.col1_description}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add column 2 Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="col2_description"
              value={editMode ? RowData.col2_description : userdata.col2_description}
              onChange={(newContent) => contentFieldChanged('col2_description', newContent)}
            />
            {errors.col2_description && <p style={{ color: '#e3342f' }}>{errors.col2_description}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add column 3 Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="col3_description"
              value={editMode ? RowData.col3_description : userdata.col3_description}
              onChange={(newContent) => contentFieldChanged('col3_description', newContent)}
            />
            {errors.col3_description && <p style={{ color: '#e3342f' }}>{errors.col3_description}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Product Features (Add Fullstop after each feature)
            </Typography>
            <JoditEditor
              ref={editor}
              name="features"
              value={editMode ? RowData.features : userdata.features}
              onChange={(newContent) => contentFieldChanged('features', newContent)}
            />
            {errors.features && <p style={{ color: '#e3342f' }}>{errors.features}</p>}
          </Stack>
        </Box>

        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Pricing
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 3 }}>
          Price related inputs ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px', mb: 5 }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              id="outlined-select-product_currency"
              select
              label="Select"
              defaultValue={editMode ? RowData.product_currency : 'INR'}
              onChange={handleproduct_currencyChange}
              helperText="Please select your product_currency"
            >
              {currencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              label="Regular Price"
              name="cost_price"
              value={editMode ? RowData.cost_price : userdata.cost_price}
              onChange={handleInputChange}
            />
            {errors.cost_price && <p style={{ color: '#e3342f' }}>{errors.cost_price}</p>}
            <TextField
              variant="outlined"
              label="Sale Price"
              name="selling_price"
              value={editMode ? RowData.selling_price : userdata.selling_price}
              onChange={handleInputChange}
            />
            {errors.selling_price && <p style={{ color: '#e3342f' }}>{errors.selling_price}</p>}
            <FormControlLabel
              control={<Switch defaultChecked={toogle} onChange={handleSwitchChange} aria-controls="" />}
              label="Price includes taxes"
            />
            {toogle ? null : (
              <TextField
                variant="outlined"
                label="Tax %"
                name="tax_percentage"
                value={editMode ? RowData.tax_percentage : userdata.tax_percentage}
                onChange={handleInputChange}
                helperText="Default value 0.00"
              />
            )}
          </Stack>
        </Box>

        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white',
              '&:hover': {
                bgcolor: '#333',
                color: 'white',
              },
            }}
            onClick={Submit}
          >
            {!editMode ? 'Create Product' : 'Edit Product'}
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
}
