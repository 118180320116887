/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link, useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify-icons/eva/eye-fill';
import refreshOutline from '@iconify-icons/eva/refresh-outline';
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'title', label: 'Recipent', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone_number', label: 'Phone Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const ViewResponses = () => {
  
  const navigate = useNavigate();

  const loginData = JSON.parse(localStorage.getItem('login'));

  const createPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[11 * 4 + 1] === '1';

  const editPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[11 * 4 + 2] === '1';

  const deletePermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[11 * 4 + 3] === '1';

  const Unauthorized = () => {
    navigate('/Unauthorized');
  };

  const [open, setOpen] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [test, setTest] = useState([]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // const handleView = (clickedRowData) => {
  //   // const history = useHistory();
  //   // console.log('This is View', clickedRowData);
  //   // const baseUrl = 'http://localhost:3007/';
  //   const fullPath = clickedRowData.file_path;

  //   const newTab = window.open(fullPath, '_blank');
  //   if (newTab) {
  //     newTab.focus(); // Focus on the new tab if it opened successfully
  //   } else {
  //     // Handle situations where the popup blocker might prevent opening a new tab
  //     // You can notify the user or provide alternative instructions
  //     console.error('The new tab was blocked or could not be opened.');
  //   }
  //   // setAnchorEl(event.currentTarget); // This sets the anchor element for the popover
  // };

  const handleView = (row) => {
    axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/portal_download_pdf', { id: row.id }, { responseType: 'blob' })
      .then((response) => {
        console.log("This is Response",response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'filename.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Error downloading file: ', error);
        alert("File Not Present");
      });
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const EditUser = (clickedRowData) => {
    // console.log('this is Edit User function');
    openchange(true);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = test.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
    // console.log(selected);
  };

  const handleClick = (event, title) => {
    setSelected((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(title);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(prevSelected, title);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(prevSelected.slice(1));
      } else if (selectedIndex === prevSelected.length - 1) {
        newSelected = newSelected.concat(prevSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(prevSelected.slice(0, selectedIndex), prevSelected.slice(selectedIndex + 1));
      }
      // console.log(newSelected); // Log the updated selection
      return newSelected;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatusChange = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1//careers/portalhandlestatuschange', row)
      .then((res) => {
        if (res.data.status === true) {
          alert('Status Changed Successfully');
          console.log("Response Data",res.data)
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err));
    setOpen(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(test, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [clickedRowData, setClickedRowData] = useState(null);

  // const handleDeleteRow = (row) => {
  //   axios
  //     .post('http://localhost:3006/DeleteRowViewResponses', row)
  //     .then((res) => {
  //       if (res.data === 'Success') {
  //         alert('Entry Deleted Successully');
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setOpen(null);
  // };

  // const handleDeleteSelected = (selected) => {
  //   // console.log(selected,parsedRow.job_number);
  //   const jobNumber = parsedRow.job_number;
  //   axios
  //     .post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/portal_delete_career_responses', selected)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         // alert('Success');
  //         // console.log(res.data.response.affectedRows);
  //         axios
  //           .post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/portal_decrement_career_response', {
  //             val: res.data.response.affectedRows,
  //             job_number: jobNumber,
  //           })
  //           .then((res) => {
  //             if (res.data.status === true) {
  //               alert('Successfully deleted');
  //             } else {
  //               alert( res.data);
  //             }
  //           });
  //       } else {
  //         alert( res.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setSelected([]);
  // };

  const [open1, openchange] = useState(false);

  const [userdata, setUserdata] = useState({
    title: '',
    email: '',
    phone: '',
    status: 'Banned',
  });

  const closepopup = () => {
    setUserdata({
      ...userdata,
      title: '',
    });
    openchange(false);
  };

  const AddUser = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/portal_edit_career_responses', clickedRowData)
      .then((res) => {
        if (res.data.status === true) {
          alert('Added');
        } else {
          alert(res.data);
        }
      })
      .catch((err) => console.log(err));

    // console.log('This is Add User function');
    openchange(false);
    // console.log(row);
  };

  // axios
  //     .post('http://localhost:3006/ViewResponses/DeleteRows', selected)
  //     .then((res) => {
  //       alert('Success');
  //       console.log(res.data);
  //     })
  //     .catch((err) => console.log(err));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClickedRowData({
      ...clickedRowData,
      [name]: value,
    });
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setUserdata({
  //     ...userdata,
  //     [name]: value,
  //   });
  // };

  const row = localStorage.getItem('data');
  const parsedRow = JSON.parse(row);
  // console.log(parsedRow);
  // console.log(parsedRow.job_number);

  useEffect(() => {
    const dynamicCondition = parsedRow.job_number;
    axios
      .post(
        `https://websiteportal.rajkamalbarscan.com/api/v1/careers/portalviewcareerresponses?condition=${dynamicCondition}`
      )
      .then((res) => {
        setTest(...test, res.data.response);
        // console.log(res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   console.log(test);
  // }, [test]);

  return (
    <>
      <Helmet>
        <title> CaseStudyList Page |RKBS </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Response List for :- {parsedRow.title} JobNumber :- {parsedRow.job_number}
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={Link}
            to="/dashboard/careers"
            sx={{ marginLeft: 'auto' }}
          >
            New Job Post
          </Button> */}
          <div style={{ textAlign: 'center' }}>
            <Dialog
              // fullScreen
              open={open1}
              onClose={closepopup}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                EDIT USER{' '}
                <IconButton onClick={closepopup} style={{ float: 'right' }}>
                  <CloseIcon color="primary" />
                </IconButton>{' '}
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>Do you want remove this user?</DialogContentText> */}
                <Stack spacing={2} margin={2}>
                  <TextField
                    variant="outlined"
                    label="Recipient"
                    name="title"
                    value={clickedRowData ? clickedRowData.title : userdata.title}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Email"
                    name="email"
                    value={clickedRowData ? clickedRowData.email : userdata.email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Phone"
                    name="phone_number"
                    value={clickedRowData ? clickedRowData.phone_number : userdata.phone}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Description"
                    name="description"
                    value={clickedRowData ? clickedRowData.description : null}
                    onChange={handleInputChange}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="success" variant="contained" onClick={() => AddUser(clickedRowData)}>
                  Edit
                </Button>
                <Button onClick={closepopup} color="error" variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={null}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={null}
            selected={selected}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, phone_number, email, status } = row;
                    const selectedUser = selected.indexOf(id) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{phone_number}</TableCell>
                        <TableCell align="left">
                          {/* Assuming 'status' holds the current status value */}
                          <Label
                            style={{
                              color: status === 'Responded' ? '#ffc107' : status === 'New' ? 'red' : 'green',
                              backgroundColor: 'transparent' /* Lighten Responded background */,
                            }}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        {/* <TableCell align="left">{category}</TableCell>
                        <TableCell align="left">{product_code}</TableCell> */}
                        {/* <TableCell align="left">
                          <Label color={(status === 'Inactive' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, row);
                              setClickedRowData(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack>
          <MenuItem onClick={() => handleView(clickedRowData)}>
            <Iconify icon={eyeFill} sx={{ mr: 2 }} />
            View
          </MenuItem>
          <MenuItem onClick={editPermissions ? () => EditUser(clickedRowData) : Unauthorized}>
            {/* {console.log(clickedRowData)} */}
            {localStorage.setItem('CareersPageRowData', JSON.stringify(clickedRowData))}
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          {clickedRowData && clickedRowData.status!=='Closed'?<MenuItem onClick={editPermissions ? () => handleStatusChange(clickedRowData) : Unauthorized}>
            <Icon icon={refreshOutline} sx={{ mr: 2, fontWeight: 'bold' }} />
            Change Status
          </MenuItem>:null}

          {/* <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteRow(clickedRowData)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Stack>
      </Popover>
    </>
  );
};

export default ViewResponses;
