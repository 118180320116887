/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import GetAppIcon from '@mui/icons-material/GetApp';
// import Select from 'react-select';
// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Switch,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// import { Edit } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  //   { id: 'id', label: 'ID', alignRight: false },
  { id: 'title', label: 'Name', alignRight: false },
  { id: 'company_name', label: 'Company', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'date_created', label: 'Date', alignRight: false },
  // { id: 'image', label: 'Image', alignRight: false },
  //   { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const ContactList = () => {
  const navigate = useNavigate();

  const loginData = JSON.parse(localStorage.getItem('login'));

  // const createPermissions =
  //   loginData && loginData.user_role_permissions && loginData.user_role_permissions[1 * 4 + 1] === '1';

  const editPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[12 * 4 + 2] === '1';

  const deletePermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[12 * 4 + 3] === '1';

  const Unauthorized = () => {
    navigate('/Unauthorized');
  };

  const [role, setRole] = useState(null);

  const [data, setData] = useState([]);

  const [test, setTest] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const userData = JSON.parse(localStorage.getItem('login'));

  const userRole = userData.role;
  //   console.log('This is UserRole', userRole);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = test.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
    // console.log(selected);
  };

  const EditUser = (clickedRowData) => {
    // console.log('this is Edit User function', clickedRowData);
    openchange(true);
    setOpen(null);
  };

  const handleClick = (event, title) => {
    setSelected((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(title);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(prevSelected, title);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(prevSelected.slice(1));
      } else if (selectedIndex === prevSelected.length - 1) {
        newSelected = newSelected.concat(prevSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(prevSelected.slice(0, selectedIndex), prevSelected.slice(selectedIndex + 1));
      }
      // console.log(newSelected);
      return newSelected;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const monthName = dateObj.toLocaleString('default', { month: 'short' });
    const formattedDate = `${dateObj.getDate()} ${monthName},${dateObj.getFullYear()}`;
    return formattedDate;
  }

  //   const handleRoleChange = async (event, id) => {
  //     const { value } = event.target;
  //     console.log('Selected role value:', value);

  //     // Ensure role is not null or undefined and id exists in role state
  //     if (role && role[id]) {
  //       setRole(
  //         (prevRole) => ({
  //           ...prevRole,
  //           [id]: { ...prevRole[id], role: value },
  //         }),
  //         () => {
  //           // Log updated role value after state change
  //           console.log('Updated role value for id', id, ':', value);
  //         }
  //       );
  //     } else {
  //       console.error('Error: Invalid role ID or null role state');
  //     }
  //   };

  const handleStatusChange = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/contact/portalcontactstatus', row)
      .then((res) => {
        if (res.data.status === true) {
          alert('Status Changed Successfully');
        }
      })
      .catch((err) => console.log(err));
    setOpen(null);
  };

  // const handleToggle = (checked) => {
  //   console.log('This is clickedrow data', checked);
  //   handleStatusChange(checked);
  //   // () => handleStatusChange(clickedRowData)
  //   // setStatus(checked ? 'Active' : 'Inactive');
  //   // Add any other logic you need when the switch is toggled
  // };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const [testlist, setUserList] = useState(test);

  const filteredUsers = applySortFilter(test, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [clickedRowData, setClickedRowData] = useState(null);

  function exportToExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(worksheet, data, {
      header: ['id', 'title', 'company_name', 'email', 'phone', 'date_created', 'status'],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'website_visitors_data');
    XLSX.writeFile(workbook, 'website_visitors_data.xlsx');
  }

  const export_xlsx = (data) => {
    exportToExcel(data, 'myExcelFile');
  };

  // const handleDeleteSelected = (selected) => {
  //   // console.log("Selected Data",selected);
  //   axios
  //     .post('https://websiteportal.rajkamalbarscan.com/api/v1/contact/portaldeletecontact', selected)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         alert('Deleted Successully');
  //       } else {
  //         alert('Error');
  //       }
  //       // console.log(res.data);
  //     })
  //     .catch((err) => console.log(err));
  //   setSelected([]);
  // };

  const [open1, openchange] = useState(false);

  const functionopenpopup = () => {
    openchange(true);
  };

  const [userdata, setUserdata] = useState({
    title: '',
    description: '',
    status: '',
    image: '',
  });

  const closepopup = () => {
    setUserdata({
      ...userdata,
      title: '',
    });
    openchange(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClickedRowData({
      ...clickedRowData,
      [name]: value,
    });
  };

  const AddUser = (row) => {
    openchange(true);
    setOpen(null);
    axios
      .post(`https://websiteportal.rajkamalbarscan.com/api/v1/contact/portaleditcontact`, row)
      .then((res) => {
        if (res.data.status === true) {
          alert('Data Edited Successfully');
        } else {
          alert(res.data);
          // console.log(res.data);
        }
      })
      .catch((err) => console.log(err));

    openchange(false);
    // console.log(row);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    if (test.length === 0) {
      axios
        .post('https://websiteportal.rajkamalbarscan.com/api/v1/contact/portalgetcontacts')
        .then((res) => {
          // Set the response data in the state
          // console.log('This is response data', res.data.response);
          setTest(...test, res.data.response);
        })
        .catch((err) => console.log(err));
    }
  }, [test, filteredUsers, data]);

  // useEffect(() => {
  //   console.log(test);
  // }, [test]);

  return (
    <>
      <Helmet>
        <title> Contact List | RKBS </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contact List
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={Link}
            to="/dashboard/AddUser"
            sx={{ marginLeft: 'auto' }}
            // onClick={handleNavigate}
          >
            ADD USER
          </Button> */}
          <Button
            variant="contained"
            startIcon={<GetAppIcon />}
            component={Link}
            sx={{
              marginLeft: 'auto',
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'dimgray', // Change this to your desired color
              },
            }}
            onClick={() => export_xlsx(test)}
          >
            Download Excel
          </Button>
          <div style={{ textAlign: 'center' }}>
            <Dialog
              // fullScreen
              open={open1}
              onClose={closepopup}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                EDIT{' '}
                <IconButton onClick={closepopup} style={{ float: 'right' }}>
                  <CloseIcon color="primary" />
                </IconButton>{' '}
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>Do you want remove this user?</DialogContentText> */}
                <Stack spacing={2} margin={2}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    name="title"
                    value={clickedRowData ? clickedRowData.title : userdata.title}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Phone"
                    name="phone"
                    value={clickedRowData ? clickedRowData.phone : userdata.phone}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Company"
                    name="company_name"
                    value={clickedRowData ? clickedRowData.company_name : userdata.company_name}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Email"
                    name="email"
                    value={clickedRowData ? clickedRowData.email : userdata.email}
                    onChange={handleInputChange}
                  />
                  {/* {console.log('This is Data', data)} */}
                  {/* <TextField
                    id="outlined-select-category"
                    select
                    label="Select"
                    defaultValue={clickedRowData ? clickedRowData.role : 'Other'}
                    onChange={handleRoleChange}
                    helperText="Please select User Role"
                  >
                    {data.map((category) => (
                      <MenuItem key={category.title} value={category.title}>
                        {category.title}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  {/* <Select
                    variant="outlined"
                    label="Role"
                    name="role"
                    options={data.map((role) => ({ value: role.title, label: role.title }))} // Map roles to options
                    value={clickedRowData ? clickedRowData.role : userdata.role}
                    onChange={(event) => handleFormRoleChange(event, data, clickedRowData)}
                    placeholder="Select a role" // Add a placeholder for clarity
                  /> */}
                  {/* {console.log('This is Clickrowdata role', userdata.role)} */}
                  {/* <TextField
                    variant="outlined"
                    label="Role"
                    name="role"
                    value={clickedRowData ? clickedRowData.role : userdata.role}
                    onChange={handleInputChange}
                  /> */}
                  {/* <TableCell align="left">
                          <select
                            value={selectedRole[id] || role || ''}
                            onChange={(event) => handleRoleChange(event, id)}
                          >
                            {filteredRoles(userRole, role).map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.title}
                              </option>
                            ))}
                          </select>
                        </TableCell> */}
                </Stack>
              </DialogContent>
              <DialogActions>
                {/* {console.log('This is ClickRowData', clickedRowData)} */}
                <Button color="success" variant="contained" onClick={() => AddUser(clickedRowData)}>
                  Edit
                </Button>
                <Button onClick={closepopup} color="error" variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={null}
            onFilterName={handleFilterByName}
            onDelete={null}
            selected={selected}
          />

          {/* <GetAppIcon style={{ marginLeft: 'auto' }} />
        <span className="font-black" style={{ marginLeft: 16 }}>Download Excel</span> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, company_name, email, status, phone, date_created } = row;
                    const selectedUser = selected.indexOf(id) !== -1;
                    const formattedDate = formatDate(date_created);
                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id, id)} />
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{company_name}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{formattedDate}</TableCell>
                        <TableCell align="left">
                          {/* Assuming 'status' holds the current status value */}
                          <Label
                            style={{
                              color: status === 'Responded' ? '#ffc107' : status === 'New' ? 'red' : 'green',
                              backgroundColor: 'transparent' /* Lighten Responded background */,
                            }}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>

                        {/* <TableCell align="left">
                          <Label color={(status === 'New' && 'error')|| (status === 'Responsed' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, row);
                              setClickedRowData(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack>
          <MenuItem onClick={editPermissions ? () => EditUser(clickedRowData) : Unauthorized}>
            {/* {console.log(clickedRowData)} */}
            {localStorage.setItem('CareersPageRowData', JSON.stringify(clickedRowData))}
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem onClick={editPermissions ? () => handleStatusChange(clickedRowData) : Unauthorized}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Status
          </MenuItem>
          {/* <MenuItem
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => handleStatusChange(clickedRowData)}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Change Status
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            <Typography variant="inherit">
              {clickedRowData && clickedRowData.status === 'Inactive' ? (
                <span style={{ color: 'green' }}>Activate</span>
              ) : (
                <span style={{ color: 'red' }}>Deactivate</span>
              )}
            </Typography>
          </MenuItem> */}

          {/* <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteRow(clickedRowData)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Stack>
      </Popover>
    </>
  );
};

export default ContactList;
