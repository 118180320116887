import React, { useState, useRef } from 'react';
import { Container, Box, TextField, Button, Typography, Stack, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import JoditEditor from 'jodit-react';
import axios from 'axios';

const Careers = () => {
  const editor = useRef(null);

  const [careers, setCareers] = useState({
    title: '',
    job_id: '',
    job_role: '',
    salary: '',
    job_location: '',
    job_objective: '',
    job_brief: '',
  });

  const [errors, setErrors] = useState({});

  const newErrors = {};

  const [handleSubmit, setHandleSubmit] = useState(false);
/* eslint-disable camelcase */
  const [verifiedjob_id, setVerifiedjob_id] = useState(false);

  const [verifySubmit, setVerifySubmit] = useState(false);
  // console.log(careers)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setCareers((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const handleEditorChange = (name, content) => {
    setCareers({
      ...careers,
      [name]: content,
    });
  };

  const storedRowData = JSON.parse(localStorage.getItem('CareersPageRowData'));
  // console.log('This is row Data', storedRowData);
  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : careers.title,
    job_id: storedRowData ? storedRowData.job_id : careers.job_id,
    status: storedRowData ? storedRowData.status : careers.status,
    id: storedRowData ? storedRowData.id : 0,
    salary: storedRowData ? storedRowData.salary : careers.salary,
    job_location: storedRowData ? storedRowData.job_location : careers.job_location,
    job_brief: storedRowData ? storedRowData.job_brief : careers.job_brief,
    job_objective: storedRowData ? storedRowData.job_objective : careers.job_objective,
    job_role: storedRowData ? storedRowData.job_role : careers.job_role,
  });
  // console.log(storedRowData)

  const verifyjob_id = (job_id) => {
    // console.log(job_id);
    if (!careers.job_id) newErrors.job_id = 'Job Number is required field';
    else if (careers.job_id.length !== 6) newErrors.job_id = 'Job Number lenght should be 6';
    if (Object.keys(newErrors).length === 0) {
      axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/verifyjobnumber', job_id).then((res) => {
        // console.log(res.data.response[0].jobnumber_exists);
        if (res.data.response[0].jobnumber_exists === 0) {
          newErrors.job_id = '';
          setErrors(newErrors);
          setVerifySubmit(true);
          setTimeout(() => {
            setVerifySubmit(false);
          }, 5000);
          setVerifiedjob_id(true);
        } else {
          newErrors.job_id = 'Job Number is Already Used';
          setErrors(newErrors);
        }
      });
    } else {
      setErrors(newErrors);
    }
  };

  const Submit = () => {
    // For Addition of new Career
    // console.log("This is RowData",RowData)
    // console.log("This is Careers",careers)
    // console.log("This is storedRowdata",storedRowData)
    if (!editMode) {
      if (!careers.title) newErrors.title = 'Title is a required field!';
      if (!careers.job_id) newErrors.job_id = 'job_id is a required field!';
      if(!verifiedjob_id)newErrors.job_id='job_id is not Verified !'
      if (!careers.job_role) newErrors.job_role = 'This is a required field!';
      if (!careers.job_brief) newErrors.job_brief = 'This is a required field!';
      if (!careers.job_objective) newErrors.job_objective = 'This is a required field!';
      if (!careers.job_location) newErrors.job_location = 'This is a required field!';
      if (!careers.salary) newErrors.salary = 'This is a required field!';
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0 && verifiedjob_id) {
        axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/submitnewcareers', careers).then((res) => {
          if (res.data.status === true) {
            setHandleSubmit(true);
            setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
            setCareers({
              title: '',
              job_id: '',
              job_brief: '',
              job_objective: '',
              job_role: '',
              salary: '',
              job_location: '',
            });
          } else {
            alert("Error")
            // console.log(res.data);
          }
        });
      }
    } else if (editMode) {
      if (!RowData.title) newErrors.title = 'Title is a required field!';
      // if(!RowData.job_id) newErrors.job_id="Job Number is a required field!";
      // else if(RowData.job_id.length!==6) newErrors.job_id="Job Number lenght should be 6";
      if (!RowData.job_role) newErrors.job_role = 'This is a required field!';
      if (!RowData.job_brief) newErrors.job_brief = 'This is a required field!';
      if (!RowData.job_objective) newErrors.job_objective = 'This is a required field!';
      if (!RowData.job_location) newErrors.job_location = 'This is a required field!';
      if (!RowData.salary) newErrors.salary = 'This is a required field!';
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/careers/portaldeditcareers', RowData).then((res) => {
          if (res.data.status === true) {
            setHandleSubmit(true);
            setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
            setRowdata({
              title: '',
              job_id: '',
              job_brief: '',
              job_objective: '',
              job_role: '',
              salary: '',
              job_location: '',
            });
          } else {
            alert("Error");
            // console.log(res.data);
          }
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard:Careers | Admin</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Carrer Post
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, sub-title ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="Careers Title"
              name="title"
              value={editMode ? RowData.title : careers.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}
            <TextField
              variant="outlined"
              label="Job Number"
              name="job_id"
              value={editMode ? RowData.job_id : careers.job_id}
              onChange={handleInputChange}
              inputProps={{ maxLength: 6 }}
              disabled={editMode}
            />
            {errors.job_id && <p style={{ color: '#e3342f' }}>{errors.job_id}</p>}

            {/* {console.log(RowData.job_id)} */}
            {!editMode && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    width: '100px',
                    height: '30px',
                    borderRadius: '5px',
                    bgcolor: 'black',
                    fontSize: '20px',
                    fontFamily: 'monospace',
                    color: 'white', // Set the default text color to white
                    '&:hover': {
                      bgcolor: '#333', // Change the background color to dark grey on hover
                      color: 'white', // Set the text color to white on hover
                    },
                  }}
                  onClick={() => verifyjob_id(careers)}
                >
                  Verify
                </Button>
              </div>
            )}
            {verifySubmit ? (
              <Alert severity="success" onClose={() => setVerifySubmit(false)} sx={{ fontSize: '20px' }}>
                Number Verified
              </Alert>
            ) : null}
            <TextField
              variant="outlined"
              label="Salary"
              name="salary"
              value={editMode ? RowData.salary : careers.salary}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.salary && <p style={{ color: '#e3342f' }}>{errors.salary}</p>}
            <TextField
              variant="outlined"
              label="job_location"
              name="job_location"
              value={editMode ? RowData.job_location : careers.job_location}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.job_location && <p style={{ color: '#e3342f' }}>{errors.job_location}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Job Role
            </Typography>
            <JoditEditor
              ref={editor}
              name="job_role"
              value={editMode ? RowData.job_role : careers.job_role}
              onChange={(content) => handleEditorChange('job_role', content)}
            />
            {errors.job_role && <p style={{ color: '#e3342f' }}>{errors.job_role}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Job Objectives
            </Typography>

            <JoditEditor
              ref={editor}
              name="job_objective"
              value={editMode ? RowData.job_objective : careers.job_objective}
              onChange={(content) => handleEditorChange('job_objective', content)}
            />
            {errors.job_objective && <p style={{ color: '#e3342f' }}>{errors.job_objective}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Job Brief
            </Typography>

            <JoditEditor
              ref={editor}
              name="job_brief"
              value={editMode ? RowData.job_brief : careers.job_brief}
              onChange={(content) => handleEditorChange('job_brief', content)}
            />
            {errors.job_brief && <p style={{ color: '#e3342f' }}>{errors.job_brief}</p>}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            {editMode?'Edit':'Create'}
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              Carrer Post Added Successfully!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Careers;
