import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';

// import FormControlContext from "@mui/material/FormControl/FormControlContext";
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

/* eslint-disable camelcase */
const TABLE_HEAD = [
  { id: 'title', label: 'Name', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'product_code', label: 'Code', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function exportToExcel(data) {
  const worksheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_json(worksheet, data, {
    header: [
      'id',
      'title',
      'img',
      'product_code',
      'product_sku',
      'product_currency',
      'cost_price',
      'selling_price',
      'tax_percentage',
      'category',
      'col1_title',
      'col2_title',
      'col3_title',
      'col1_description',
      'col2_description',
      'col3_description',
      'product_description',
      'features',
      'status',
      'brand',
    ],
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
  XLSX.writeFile(workbook, 'products.xlsx');
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

  const navigate = useNavigate();

  const loginData = JSON.parse(localStorage.getItem('login'));

  const createPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[3 * 4 + 1] === '1';

  const editPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[3 * 4 + 2] === '1';

  const deletePermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[3 * 4 + 3] === '1';

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [test, setTest] = useState([]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = test.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
    // console.log(selected);
  };

  const handleClick = (event, title) => {
    setSelected((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(title);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(prevSelected, title);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(prevSelected.slice(1));
      } else if (selectedIndex === prevSelected.length - 1) {
        newSelected = newSelected.concat(prevSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(prevSelected.slice(0, selectedIndex), prevSelected.slice(selectedIndex + 1));
      }
      // console.log(newSelected); // Log the updated selection
      return newSelected;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatusChange = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portalhandleproductstatus', row)
      .then((res) => {
        if (res.data.status === true) {
          alert('Status Changed Successfully');
        }
      })
      .catch((err) => console.log(err));
    setOpen(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(test, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [clickedRowData, setClickedRowData] = useState(null);

  // const handleDeleteRow = (row) => {
  //   axios
  //     .post('http://localhost:3006/dashboard/DeleteRow', row)
  //     .then((res) => {
  //       if (res.data === 'Success') {
  //         alert('Product Deleted Successully');
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setOpen(null);
  // };

  const Unauthorized = () => {
    // console.log('Hello');
    navigate('/Unauthorized')
      
  };
  

  // const handleDeleteSelected = (selected) => {
  //   // console.log(selected);
  //   axios
  //     .post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portaldeleteselectedproducts', selected)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         alert('Products Deleted Successully');
  //       }
  //       // console.log(res.data);
  //     })
  //     .catch((err) => console.log(err));
  //   setSelected([]);
  // };

  const [open1, openchange] = useState(false);

  const min = 1; // Minimum value (inclusive)

  const max = 10; // Maximum value (exclusive)

  const randomInt = Math.floor(Math.random() * (max - min)) + min;

  const [userdata, setUserdata] = useState({
    img: `/assets/images/avatars/avatar_${randomInt}.jpg`,
    title: '',
    category: '',
    phone: '',
    status: '',
    col1_description: '',
    col2_description: '',
    col3_description: '',
    col1_title: '',
    col2_title: '',
    col3_title: '',
    cost_price: '',
    selling_price: '',
    features: '',
    product_code: '',
    product_currency: '',
    product_description: '',
    product_sku: '',
    tax_percentage: '',
  });

  const closepopup = () => {
    setUserdata({
      ...userdata,
      title: '',
      selling_price: '',
      category: '',
      phone: '',
    });
    openchange(false);
  };

  const AddUser = (userdata) => {
    // console.log('User Added');
    const userExists = test.some(
      (user) =>
        user.img === userdata.Avatar &&
        user.title === userdata.title &&
        user.category === userdata.category &&
        user.selling_price === userdata.selling_price &&
        user.phone === userdata.phone
    );

    if (userExists) {
      // Handle the case where the user already exists
      alert('User already exists');
    } else {
      // Add the user to the list if they do not exist
      test.push(userdata);
      openchange(false);
      setUserdata({
        ...userdata,
        title: '',
        selling_price: '',
        category: '',
        phone: '',
      });
    }
  };

  const handleInputChange = (event) => {
    const { title, value } = event.target;
    setUserdata({
      ...userdata,
      [title]: value,
    });
  };

  const extractUniqueValues = (data, property) => {
    const uniqueValues = new Set();

    data.forEach((item) => {
      if (item[property]) {
        uniqueValues.add(item[property]);
      }
    });

    return Array.from(uniqueValues);
  };

  const allBrands = extractUniqueValues(test, 'brand');
  const allCurrencies = extractUniqueValues(test, 'product_currency');
  const allCategory = extractUniqueValues(test, 'category');

  localStorage.setItem('categories', JSON.stringify(allCategory));
  localStorage.setItem('brands', JSON.stringify(allBrands));
  localStorage.setItem('product_currency', JSON.stringify(allCurrencies));

  const hello = (data) => {
    exportToExcel(data, 'myExcelFile');
  };

  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   axios
  //     .post('http://localhost:3007/api/v1/products/portalgetcategories')
  //     .then((res) => {
  //       // Set the response data in the state
  //       setTest(...test, res.data.response);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    // Fetch data when the component mounts
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portalgetproducts')
      .then((res) => {
        // Set the response data in the state
        setTest(...test, res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   console.log(test);
  // }, [test]);

  return (
    <>
      <Helmet>
        <title> User | Admin </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Product List
          </Typography>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={Link}
            to={createPermissions ? '/dashboard/products' : '/Unauthorized'}
            sx={{ marginLeft: 'auto' }}
          >
            New Product
          </Button>

          {/* {console.log(
            'This is Login data at index',
            loginData && loginData.user_role_permissions && loginData.user_role_permissions[3 * 4 + 1] === '1'
          )} */}
          {/* const loginData = JSON.parse(localStorage.getItem('login'));
  
  const role = loginData ? loginData.role : undefined; */}

          <div style={{ textAlign: 'center' }}>
            <Dialog
              // fullScreen
              open={open1}
              onClose={closepopup}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                ADD USER{' '}
                <IconButton onClick={closepopup} style={{ float: 'right' }}>
                  <CloseIcon color="primary" />
                </IconButton>{' '}
              </DialogTitle>
              <DialogContent>
                <Stack spacing={2} margin={2}>
                  <TextField
                    variant="outlined"
                    label="Usertitle"
                    title="title"
                    value={userdata.title}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="selling_price"
                    title="selling_price"
                    value={userdata.selling_price}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="category"
                    title="category"
                    value={userdata.category}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Phone"
                    title="phone"
                    value={userdata.phone}
                    onChange={handleInputChange}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="success" variant="contained" onClick={() => AddUser(userdata)}>
                  Submit
                </Button>
                <Button onClick={closepopup} color="error" variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Stack>

        <Card>
          <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
            <UserListToolbar
              numSelected={null}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={null}
              selected={selected}
            />
            <Button
              variant="contained"
              startIcon={<GetAppIcon />}
              component={Link}
              sx={{
                marginLeft: 'auto',
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'dimgray', // Change this to your desired color
                },
              }}
              onClick={() => hello(test)}
            >
              Download Excel
            </Button>
            {/* <GetAppIcon style={{ marginLeft: 'auto' }} />
        <span className="font-black" style={{ marginLeft: 16 }}>Download Excel</span> */}
          </div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, category, product_code, img, status } = row;
                    const selectedUser = selected.indexOf(id) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={title} src={img} />
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{category}</TableCell>
                        <TableCell align="left">{product_code}</TableCell>
                        <TableCell align="left">
                          <Label color={(status === 'Inactive' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, row);
                              setClickedRowData(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack>
          <MenuItem>
            {/* {console.log('This is clickedRowData', clickedRowData)} */}
            {localStorage.setItem('row', JSON.stringify(clickedRowData))}
            <Link
              to={{
                pathname: editPermissions ?'/dashboard/products/'  :'/Unauthorized' ,
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
            </Link>
          </MenuItem>
          <MenuItem
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => handleStatusChange(clickedRowData)}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            <Typography variant="inherit">
              {clickedRowData && clickedRowData.status === 'Inactive' ? (
                <span style={{ color: 'green' }}>Activate</span>
              ) : (
                <span style={{ color: 'red' }}>Deactivate</span>
              )}
            </Typography>
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }} >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
          {console.log('Filtered users', filteredUsers)} */}
        </Stack>
      </Popover>
    </>
  );
}
