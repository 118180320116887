import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Box, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';

const Brand = () => {
  const [brand, setbrand] = useState({
    title: '',
    brand_code: '',
    rank:'',
    status: '',
    id: '',
  });

  const [errors, setErrors] = useState({});

  const [handleSubmit, setHandleSubmit] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setbrand((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const storedRowData = JSON.parse(localStorage.getItem('brandrow'));

  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : brand.title,
    rank: storedRowData ? storedRowData.rank : brand.rank,
    status: storedRowData ? storedRowData.status : brand.status,
    id: storedRowData ? storedRowData.id : 0,
    // des: storedRowData ? storedRowData.des : brand.des,
    brand_code: storedRowData ? storedRowData.brand_code : brand.brand_code,
  });

  // const handleEditorChange = (name, content) => {
  //   setbrand({
  //     ...brand,
  //     [name]: content,
  //   });
  // };

  const Submit = () => {
    // Initialize newErrors object
    const newErrors = {};

    if (!editMode) {
      // if (!brand.title) newErrors.title = 'Title is a required field!';
      // if (!brand.des)
      //   newErrors.des = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      // else if (brand.des.length > 1500) newErrors.des = 'Description length is greater than 1500'; // Changed 300 to 1500

      // setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/products/portaladdbrand', brand)
          .then((res) => {
            if (res.data.status === true) {
              setHandleSubmit(true);
              setTimeout(() => {
                setHandleSubmit(false);
              }, 5000);
              // console.log(brand);
            } else {
              alert("Error")
              // console.log(res.data);
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setbrand({
          title: '',
          brand_code: '',
          rank:''
        });
      }
    } else if (editMode) {
      if (!RowData.title) newErrors.title = 'Title is a required field!';
      if (!RowData.brand_code) newErrors.brand_code = 'brand_code is a required field!';
      // if (!RowData.des)
      //   newErrors.des = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/case-studies/portaleditbrand', RowData).then((res) => {
          if (res.data.status === true) {
            setRowdata({
              title: '',
              status: '',
              id: null,
              brand_code: '',
            });
            setHandleSubmit(true);
            localStorage.removeItem('brandrow');
            setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
          } else {
            alert("Error")
            // console.log(res.data);
          }
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | brand</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Brand
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, Brand Code, Ranking ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Title
            </Typography>
            <TextField
              variant="outlined"
              label="Brand Title"
              name="title"
              value={editMode ? RowData.title : brand.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Brand code
            </Typography>
            <TextField
              variant="outlined"
              label="brand Code"
              name="brand_code"
              value={editMode ? RowData.brand_code : brand.brand_code}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            {errors.brand_code && <p style={{ color: '#e3342f' }}>{errors.brand_code}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
             Add Ranking
            </Typography>
            <TextField
              variant="outlined"
              label="Brand Ranking"
              name="rank"
              value={editMode ? RowData.rank : brand.rank}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.rank && <p style={{ color: '#e3342f' }}>{errors.rank}</p>}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            Create
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Brand;
