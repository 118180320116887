import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Box, TextField, Button, Switch, FormControlLabel, Alert } from '@mui/material';
import JoditEditor from 'jodit-react';
import axios from 'axios';

const CaseStudy = () => {
  const userData=JSON.parse(localStorage.getItem('login'));
  const user=userData.title
  const [casestudy, setCaseStudy] = useState({
    title: '',
    sub_title: '',
    summary: '',
    des1: '',
    des2: '',
    des3: '',
    des4: '',
    results: '',
    created_by:user
  });

  const [errors, setErrors] = useState({});

  const editor = useRef(null);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setCaseStudy((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const storedRowData = JSON.parse(localStorage.getItem('CaseStudyrow'));
  
  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : casestudy.title,
    status: storedRowData ? storedRowData.status : casestudy.status,
    id: storedRowData ? storedRowData.id : 0,
    sub_title: storedRowData ? storedRowData.sub_title : casestudy.sub_title,
    summary: storedRowData ? storedRowData.summary : casestudy.summary,
    results: storedRowData ? storedRowData.results : casestudy.results,
    des1: storedRowData ? storedRowData.des1 : casestudy.des1,
    des2: storedRowData ? storedRowData.des2 : casestudy.des2,
    des3: storedRowData ? storedRowData.des3 : casestudy.des3,
    des4: storedRowData ? storedRowData.des4 : casestudy.des4,
  });

  // console.log(RowData)

  const handleEditorChange = (name, content) => {
    setCaseStudy({
      ...casestudy,
      [name]: content,
    });
  };

  const Submit = () => {
    // Initialize newErrors object
    const newErrors = {};

    if (!editMode) {
      if (!casestudy.title) newErrors.title = 'Title is a required field!';
      if (!casestudy.sub_title) newErrors.sub_title = 'Sub Title is a required field!';
      if (!casestudy.summary) newErrors.summary = 'Summary is a required field!';
      else if (casestudy.summary.length > 2000) newErrors.summary = 'Summary length is greater than 2000'; // Changed 300 to 2000
      if (!casestudy.des1)
        newErrors.des1 = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      else if (casestudy.des1.length > 1500) newErrors.des1 = 'Description 1 length is greater than 1500'; // Changed 300 to 1500
      if (!casestudy.des2)
        newErrors.des2 = 'Description 2 is a required field!'; // Added a space between "Description" and "2"
      else if (casestudy.des2.length > 1500) newErrors.des2 = 'Description 2 length is greater than 1500'; // Changed 300 to 1500
      if (!casestudy.des3)
        newErrors.des3 = 'Description 3 is a required field!'; // Added a space between "Description" and "3"
      else if (casestudy.des3.length > 1500) newErrors.des3 = 'Description 3 length is greater than 1500'; // Changed 300 to 1500
      if (!casestudy.des4)
        newErrors.des4 = 'Description 4 is a required field!'; // Added a space between "Description" and "4"
      else if (casestudy.des4.length > 1500) newErrors.des4 = 'Description 4 length is greater than 1500'; // Changed 300 to 1500
      if (!casestudy.results)
        newErrors.results = 'Results is a required field!'; // Added a space between "Results" and "is"
      else if (casestudy.results.length > 1500) newErrors.results = 'Results length is greater than 1500'; // Changed 300 to 1500
      setErrors(newErrors);
      // console.log("Data",casestudy);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/case-studies/portalcreatenewcasestudy', casestudy)
          .then((res) => {
            if (res.data.status === true) {
              setHandleSubmit(true);
              setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
              // console.log(casestudy)
            } else {
              alert("Error")
              // console.log(res.data);
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setCaseStudy({
          title: '',
          sub_title: '',
          summary: '',
          des1: '',
          des2: '',
          des3: '',
          des4: '',
          results: '',
        });
      }
    } else if (editMode) {
      if (!RowData.title) newErrors.title = 'Title is a required field!';
      if (!RowData.sub_title) newErrors.sub_title = 'Sub Title is a required field!';
      if (!RowData.summary) newErrors.summary = 'Summary is a required field!';
      else if (RowData.summary.length > 2000) newErrors.summary = 'Summary length is greater than 2000'; // Changed 300 to 2000
      if (!RowData.des1)
        newErrors.des1 = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      else if (RowData.des1.length > 1500) newErrors.des1 = 'Description 1 length is greater than 1500'; // Changed 300 to 1500
      if (!RowData.des2)
        newErrors.des2 = 'Description 2 is a required field!'; // Added a space between "Description" and "2"
      else if (RowData.des2.length > 1500) newErrors.des2 = 'Description 2 length is greater than 1500'; // Changed 300 to 1500
      if (!RowData.des3)
        newErrors.des3 = 'Description 3 is a required field!'; // Added a space between "Description" and "3"
      else if (RowData.des3.length > 1500) newErrors.des3 = 'Description 3 length is greater than 1500'; // Changed 300 to 1500
      if (!RowData.des4)
        newErrors.des4 = 'Description 4 is a required field!'; // Added a space between "Description" and "4"
      else if (RowData.des4.length > 1500) newErrors.des4 = 'Description 4 length is greater than 1500'; // Changed 300 to 1500
      if (!RowData.results)
        newErrors.results = 'Results is a required field!'; // Added a space between "Results" and "is"
      else if (RowData.results.length > 1500) newErrors.results = 'Results length is greater than 1500'; // Changed 300 to 1500
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/case-studies/portaleditcasestudy', RowData).then((res) => {
          if (res.data.status === true) {
            setRowdata({
              title: '',
              status: '',
              id: 0,
              sub_title: '',
              summary: '',
              results: '',
              des1: '',
              des2: '',
              des3: '',
              des4: '',
            });
            setHandleSubmit(true);
            localStorage.removeItem('CaseStudyrow');
            setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
          } else {
            alert("Error");
            // console.log(res.data);
          }
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | CaseStudy</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Case Study
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, sub-title ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <TextField
              variant="outlined"
              label="Case Study Title"
              name="title"
              value={editMode ? RowData.title : casestudy.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <TextField
              variant="outlined"
              label="Case Study Sub_Title"
              name="sub_title"
              value={editMode ? RowData.sub_title : casestudy.sub_title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            {errors.sub_title && <p style={{ color: '#e3342f' }}>{errors.sub_title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Case Study Summary
            </Typography>
            <JoditEditor
              ref={editor}
              name="summary"
              value={editMode ? RowData.summary : casestudy.summary}
              onChange={(content) => handleEditorChange('summary', content)}
            />
            {errors.summary && <p style={{ color: '#e3342f' }}>{errors.summary}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Challenges and Solutions
            </Typography>
            <JoditEditor
              ref={editor}
              name="des1"
              value={editMode ? RowData.des1 : casestudy.des1}
              onChange={(content) => handleEditorChange('des1', content)}
            />
            {errors.des1 && <p style={{ color: '#e3342f' }}>{errors.des1}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              The Journey
            </Typography>
            <JoditEditor
              ref={editor}
              name="des2"
              value={editMode ? RowData.des2 : casestudy.des2}
              onChange={(content) => handleEditorChange('des2', content)}
            />
            {errors.des2 && <p style={{ color: '#e3342f' }}>{errors.des2}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Success Story
            </Typography>
            <JoditEditor
              ref={editor}
              name="des3"
              value={editMode ? RowData.des3 : casestudy.des3}
              onChange={(content) => handleEditorChange('des3', content)}
            />
            {errors.des3 && <p style={{ color: '#e3342f' }}>{errors.des3}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Key Highlights
            </Typography>
            <JoditEditor
              ref={editor}
              name="des4"
              value={editMode ? RowData.des4 : casestudy.des4}
              onChange={(content) => handleEditorChange('des4', content)}
            />
            {errors.des4 && <p style={{ color: '#e3342f' }}>{errors.des4}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              CaseStudy Results
            </Typography>
            <JoditEditor
              ref={editor}
              name="results"
              value={editMode ? RowData.results : casestudy.results}
              onChange={(content) => handleEditorChange('results', content)}
            />
            {errors.results && <p style={{ color: '#e3342f' }}>{errors.results}</p>}
            {/* <Typography variant="body1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Image Link
            </Typography>
            <TextField
              variant="outlined"
              label="Image Link"
              name="image"
              // value={editMode ? RowData.image : userdata.image}
              // onChange={handleInputChange}
            /> */}
            {/* {errors.image and <p style={{ color: '#e3342f' }}>{errors.image}</p>} */}
            {/* 
            <FileUploader
              multiple
              handleChange={handleChange}
              name="file"
              types={['JPEG', 'PNG', 'GIF']}
              style={{ width: '500px', height: '200px' }}
            />
            <p>{file ? `File name: ${file[0].name}` : 'No files uploaded yet'}</p> 
            */}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            Create
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default CaseStudy;
