// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'User',
    icon: icon('ic_user'),
    submenu: [
      { title: 'UserList', path: '/dashboard/user', icon: icon('ic_user') },
      { title: 'Roles', path: '/dashboard/roles', icon: icon('ic_user') }
    ],
  },
  {
    title: 'Product',
    icon: icon('ic_blog'),
    submenu: [
      { title: 'Product_list', path: '/dashboard/list', icon: icon('ic_blog') },
      {
        title: 'Categories',
        path: '/dashboard/category',
        icon: icon('ic_blog'),
      },
      {
        title: 'Brand',
        path: '/dashboard/brand',
        icon: icon('ic_blog'),
      },
      // {
      //   title: 'BrandList',
      //   path: '/dashboard/AddBrand',
      //   icon: icon('ic_blog'),
      // }
    ],
  },

  {
    title: 'CaseStudy',
    icon: icon('ic_blog'),
    submenu: [
      { title: 'CaseStudyList', path: '/dashboard/caseStudyList', icon: icon('ic_blog') },
      {
        title: 'Add_Case_Study',
        path: '/dashboard/caseStudy',
        icon: icon('ic_blog'),
      },
    ],
  },
  {
    title: 'Solution',
    icon: icon('ic_blog'),
    submenu: [
      {title: 'Solutions_List',
      path: '/dashboard/solutionsList',
      icon: icon('ic_blog'),},
      {
        title: 'Add_Solution',
        path: '/dashboard/solutions',
        icon: icon('ic_blog'),
      },
    ],
  },

  {
    title: 'About',
    
    icon: icon('ic_blog'),
    submenu: [
      {title: 'Edit_About',
      path: '/dashboard/about',
      icon: icon('ic_blog')}
    ],
  },
  {
    title: 'Customers',
    icon: icon('ic_user'),
    submenu: [
      {title: 'CustomerList',
      path: '/dashboard/customerList',
      icon: icon('ic_blog')}
    ],
  },
  {
    title: 'Contact',
    icon: icon('ic_blog'),
    submenu: [
      {title: 'Contact',
      path: '/dashboard/contact',
      icon: icon('ic_blog')}
    ],
  },
  {
    title: 'Career',
    icon: icon('ic_blog'),
    submenu: [
      {title: 'CareerList',
      path: '/dashboard/careerList',
      icon: icon('ic_blog')}
    ],
  },
  {
    title: 'Error',
    path: '/404',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;
