import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Box,
  TextField,
  Checkbox,
  Button,
  Switch,
  FormControlLabel,
  Alert,
  label,
} from '@mui/material';
import axios from 'axios';

const AddRole = () => {
  const pages = [
    'UserList',
    'RoleList',
    'CaseStudyList',
    'ProductList',
    'CategoryList',
    'CustomerList',
    'BrandList',
    'SolutionsList',
    'About',
    'CareerList',
    'QuotationDetails',
    'viewResponses',
    'Contact'
  ];

  const [role, setRole] = useState({
    title: '',
  });

  const storedRowData = JSON.parse(localStorage.getItem('RoleSelected'));
  // console.log('This is stored Row data', storedRowData);

  const user = JSON.parse(localStorage.getItem('login'));

  const defaultPermissions = Array(pages.length * 4).fill(false);

  // console.log('This is default permissions', defaultPermissions);

  const [errors, setErrors] = useState({});

  const [handleSubmit, setHandleSubmit] = useState(false);

  const permissionsArray = storedRowData?.role_permissions
    ? storedRowData.role_permissions.split('').map((permission) => permission === '1')
    : [];

  const [checkedPages, setCheckedPages] = useState(permissionsArray.length > 0 ? permissionsArray : defaultPermissions);

  const handleCheckboxChange = (index) => {
    const updatedCheckedPages = [...checkedPages];
    updatedCheckedPages[index] = !updatedCheckedPages[index];
    setCheckedPages(updatedCheckedPages);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setRole((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : role.title,
    id: storedRowData ? storedRowData.id : 0,
  });

  const binaryString = checkedPages.map((value) => (value ? '1' : '0')).join('');
  const convertedArray = binaryString.split('').map((char) => char === '1');
  // console.log('this is binary string', binaryString);
  // console.log('This is Converted Array', convertedArray);
  //   console.log("This is RowData",RowData)
  useEffect(() => {
    setCheckedPages(convertedArray);
    // console.log(checkedPages);
  }, []); // Trigger only when checkedPages changes

  const Submit = (event) => {
    event.preventDefault();
    // Initialize newErrors object

    const newErrors = {};

    if (!editMode) {
      if (!role.title) newErrors.title = 'Title is a required field!';

      // console.log('user data', user.title);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/roles/addrole', {
            Permissions: binaryString,
            title: role.title,
            createdBy: user.title,
          })
          .then((res) => {
            if (res.data.status === true) {
              alert('Role Created Successfully');
            } else {
              alert('Error in creating role');
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setRole({
          title: '',
        });
        setCheckedPages(Array(pages.length).fill(false));
      }
    } else if (editMode) {
      if (!RowData.title) newErrors.title = 'Title is a required field!';
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/roles/portaleditrole', {
            idToChange: storedRowData.id,
            title: RowData.title,
            role_permissions: binaryString
          })
          .then((res) => {
            if (res.data.status === true) {
              setRowdata({
                title: '',
                id: null,
              });
              setHandleSubmit(true);
              localStorage.removeItem('rolerow');
              setTimeout(() => {
                setHandleSubmit(false);
              }, 5000);
            } else {
              alert('Error Updating Role data');
              // console.log(res.data);
            }
          });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | role</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Role
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, Permissions ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Role Title
            </Typography>
            <TextField
              variant="outlined"
              label="Role Title"
              name="title"
              value={editMode ? RowData.title : role.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}
          </Stack>
        </Box>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Permissions
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Select Role Permissions ...
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', fontWeight: 'bold' }}>
            <Typography style={{ marginRight: '30px' }}>Pages</Typography>
            <Typography style={{ marginRight: '10px' }}>Read</Typography>
            <Typography style={{ marginRight: '10px' }}>Create</Typography>
            <Typography style={{ marginRight: '10px' }}>Update</Typography>
            <Typography style={{ marginRight: '10px' }}>Delete</Typography>
          </div>
          {pages.map((page, pageIndex) => (
            <div key={pageIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <Typography style={{ marginRight: '10px' }}>{page}</Typography>
              <div style={{ display: 'flex', marginLeft: '10px' }}>
                {[0, 1, 2, 3].map((checkboxIndex) => (
                  <Checkbox
                    key={checkboxIndex}
                    checked={checkedPages[pageIndex * 4 + checkboxIndex]}
                    onChange={() => handleCheckboxChange(pageIndex * 4 + checkboxIndex)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>

        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            {editMode ? 'Edit' : 'Create'}
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default AddRole;
