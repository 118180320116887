import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

function NavItem({ item }) {
  const { title, path, icon, info, submenu } = item;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleSubMenuClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <></>
    // <StyledNavItem
    //   component={RouterLink}
    //   to={path}
    //   sx={{
    //     '&.active': {
    //       color: 'text.primary',
    //       bgcolor: 'action.selected',
    //       fontWeight: 'fontWeightBold',
    //     },
    //     position: 'relative', // Ensure relative positioning for submenu
    //   }}
    // >
    //   <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

    //   <ListItemText disableTypography primary={title} />

    //   {info && info}

    //   {submenu && (
    //     <div >
    //     {openSubMenu ? (
    //       <ExpandLess onClick={handleSubMenuClick} />
    //     ) : (
    //       <ExpandMore onClick={handleSubMenuClick} />
    //     )}
    //     <div >{openSubMenu && (
    //       <NavSection
    //       key={title}
    //       data={submenu}
    //       sx={{
    //         position: 'absolute',
    //         top: '100%',
    //         left: 0,
    //         right:0,
    //         zIndex: 1,
    //         background: 'rgba(240, 240, 240, 0.9)', // Set the background color with opacity here
    //         borderRadius: '0 0 8px 8px',
    //       }}
    //     />
        
    //     )}</div>
    //   </div>
    //   )}
    // </StyledNavItem>
  );
}
