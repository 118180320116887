import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Box, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';

const Category = () => {
  const [category, setcategory] = useState({
    title: '',
    category_code: '',
    rank:'',
    status: '',
    id: '',
  });

  const [errors, setErrors] = useState({});

  const [handleSubmit, setHandleSubmit] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setcategory((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const storedRowData = JSON.parse(localStorage.getItem('categoryrow'));

  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : category.title,
    rank: storedRowData ? storedRowData.rank : category.rank,
    status: storedRowData ? storedRowData.status : category.status,
    id: storedRowData ? storedRowData.id : 0,
    category_code: storedRowData ? storedRowData.category_code : category.category_code,
  });

  // const handleEditorChange = (name, content) => {
  //   setcategory({
  //     ...category,
  //     [name]: content,
  //   });
  // };

  const Submit = () => {

    const newErrors = {};

      // if (!category.title) newErrors.title = 'Title is a required field!';
      // if (!category.des)
      //   newErrors.des = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      // else if (category.des.length > 1500) newErrors.des = 'Description length is greater than 1500'; // Changed 300 to 1500

      // setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/portaladdcategory', category)
          .then((res) => {
            if (res.data.status === true) {
              setHandleSubmit(true);
              setTimeout(() => {
                setHandleSubmit(false);
              }, 5000);
              // console.log(category);
            } else {
              alert(res.data);
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setcategory({
          title: '',
          category_code: '',
          rank:''
        });
      }
    
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | category</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New category
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, category Code, Ranking ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Title
            </Typography>
            <TextField
              variant="outlined"
              label="category Title"
              name="title"
              value={editMode ? RowData.title : category.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add category code
            </Typography>
            <TextField
              variant="outlined"
              label="category Code"
              name="category_code"
              value={editMode ? RowData.category_code : category.category_code}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            {errors.category_code && <p style={{ color: '#e3342f' }}>{errors.category_code}</p>}
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
             Add Ranking
            </Typography>
            <TextField
              variant="outlined"
              label="category Ranking"
              name="rank"
              value={editMode ? RowData.rank : category.rank}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.rank && <p style={{ color: '#e3342f' }}>{errors.rank}</p>}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            Create
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Category;
