import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Box, TextField, Button, Switch, FormControlLabel, Alert } from '@mui/material';
import JoditEditor from 'jodit-react';
import axios from 'axios';

const Solutions = () => {
  const userData = JSON.parse(localStorage.getItem('login'));
  const user = userData.title;
  const [solutions, setSolutions] = useState({
    title: '',
    img: '',
    des: '',
    status: '',
    id: '',
    CreatedBy: user,
  });

  const [errors, setErrors] = useState({});

  const editor = useRef(null);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editMode) {
      setRowdata((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    } else {
      setSolutions((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const storedRowData = JSON.parse(localStorage.getItem('solutionsrow'));

  const [editMode, setEditMode] = useState(!!storedRowData);

  const [RowData, setRowdata] = useState({
    title: storedRowData ? storedRowData.title : solutions.title,
    status: storedRowData ? storedRowData.status : solutions.status,
    id: storedRowData ? storedRowData.id : 0,
    des: storedRowData ? storedRowData.des : solutions.des,
    img: storedRowData ? storedRowData.img : solutions.img,
  });

  const handleEditorChange = (name, content) => {
    setSolutions({
      ...solutions,
      [name]: content,
    });
  };

  const Submit = () => {
    // Initialize newErrors object
    const newErrors = {};

    if (!editMode) {
      if (!solutions.title) newErrors.title = 'Title is a required field!';
      if (!solutions.des)
        newErrors.des = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      else if (solutions.des.length > 1500) newErrors.des = 'Description length is greater than 1500'; // Changed 300 to 1500

      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios
          .post('https://websiteportal.rajkamalbarscan.com/api/v1/solutions/portaladdsolution', solutions)
          .then((res) => {
            if (res.data.status === true) {
              setHandleSubmit(true);
              setTimeout(() => {
                setHandleSubmit(false);
              }, 5000);
              // console.log(solutions);
            } else {
              alert(res.data);
            }
          })
          .catch((err) => console.log(err));

        // Clear the form Data
        setSolutions({
          title: '',
          des: '',
          img: '',
        });
      }
    } else if (editMode) {
      if (!RowData.title) newErrors.title = 'Title is a required field!';
      if (!RowData.img) newErrors.img = 'Img is a required field!';
      if (!RowData.des)
        newErrors.des = 'Description 1 is a required field!'; // Added a space between "Description" and "1"
      else if (RowData.des.length > 1500) newErrors.des = 'Description 1 length is greater than 1500'; // Changed 300 to 1500
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        axios.post('https://websiteportal.rajkamalbarscan.com/api/v1/case-studies/portaleditsolutions', RowData).then((res) => {
          if (res.data.status === true) {
            setRowdata({
              title: '',
              status: '',
              id: null,
              img: '',
              des: '',
            });
            setHandleSubmit(true);
            localStorage.removeItem('solutionsrow');
            setTimeout(() => {
              setHandleSubmit(false);
            }, 5000);
          } else {
            alert(res.data);
          }
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>RKBS: Dashboard | solutions</title>
      </Helmet>
      <Container>
        <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
          Create a New Solutions
        </Typography>
        <Typography variant="h4" sx={{ ml: [0, null, 1], mb: 2 }}>
          Details
        </Typography>
        <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
          Title, sub-title ...
        </Typography>
        <Box sx={{ ml: [0, null, 30], border: '1px solid #ccc', padding: '30px', borderRadius: '20px' }}>
          <Stack spacing={2} margin={2} justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Title
            </Typography>
            <TextField
              variant="outlined"
              label="Solutions Title"
              name="title"
              value={editMode ? RowData.title : solutions.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {errors.title && <p style={{ color: '#e3342f' }}>{errors.title}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Image link
            </Typography>
            <TextField
              variant="outlined"
              label="Solution img link"
              name="img"
              value={editMode ? RowData.img : solutions.img}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            {errors.summary && <p style={{ color: '#e3342f' }}>{errors.summary}</p>}

            <Typography variant="subtitle1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Description
            </Typography>
            <JoditEditor
              ref={editor}
              name="des"
              value={editMode ? RowData.des : solutions.des}
              onChange={(content) => handleEditorChange('des', content)}
            />
            {errors.des && <p style={{ color: '#e3342f' }}>{errors.des}</p>}
            {/* <Typography variant="body1" sx={{ ml: [0, null, 1], mb: 2 }}>
              Add Image Link
            </Typography>
            <TextField
              variant="outlined"
              label="Image Link"
              name="image"
              // value={editMode ? RowData.image : userdata.image}
              // onChange={handleInputChange}
            /> */}
            {/* {errors.image and <p style={{ color: '#e3342f' }}>{errors.image}</p>} */}
            {/* 
            <FileUploader
              multiple
              handleChange={handleChange}
              name="file"
              types={['JPEG', 'PNG', 'GIF']}
              style={{ width: '500px', height: '200px' }}
            />
            <p>{file ? `File name: ${file[0].name}` : 'No files uploaded yet'}</p> 
            */}
          </Stack>
        </Box>
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: '200px',
              height: '50px',
              borderRadius: '5px',
              bgcolor: 'black',
              fontSize: '20px',
              fontFamily: 'monospace',
              color: 'white', // Set the default text color to white
              '&:hover': {
                bgcolor: '#333', // Change the background color to dark grey on hover
                color: 'white', // Set the text color to white on hover
              },
            }}
            onClick={Submit}
          >
            Create
          </Button>
          <br />
          {handleSubmit ? (
            <Alert severity="success" onClose={() => setHandleSubmit(false)} sx={{ fontSize: '20px' }}>
              This is a success alert!
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Solutions;
