/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function formatDate(dateStr) {
  const dateObj = new Date(dateStr);
  const monthName = dateObj.toLocaleString('default', { month: 'short' });
  const formattedDate = `${dateObj.getDate()} ${monthName},${dateObj.getFullYear()}`;
  return formattedDate;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'title', label: 'Category', alignRight: false },
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'category_code', label: 'Code', alignRight: false },
  { id: 'last_edited_on', label: 'Last Edit', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const Category = () => {
  const navigate = useNavigate();

  const loginData = JSON.parse(localStorage.getItem('login'));

  const createPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[4 * 4 + 1] === '1';

  const editPermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[4 * 4 + 2] === '1';

  const deletePermissions =
    loginData && loginData.user_role_permissions && loginData.user_role_permissions[4 * 4 + 3] === '1';

  const Unauthorized = () => {
    navigate('/Unauthorized');
  };

  const [test, setTest] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('title');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const EditUser = (clickedRowData) => {
    functionopenpopup();
    // console.log('this is Edit User function');
    openchange(true);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = test.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
    // console.log(selected);
  };

  const handleClick = (event, title) => {
    setSelected((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(title);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(prevSelected, title);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(prevSelected.slice(1));
      } else if (selectedIndex === prevSelected.length - 1) {
        newSelected = newSelected.concat(prevSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(prevSelected.slice(0, selectedIndex), prevSelected.slice(selectedIndex + 1));
      }
      // console.log(newSelected);
      return newSelected;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatusChange = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/changecategorystatus', row)
      .then((res) => {
        if (res.data.status === true) {
          alert('Status Changed Successfully');
        }
      })
      .catch((err) => console.log(err));
    setOpen(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(test, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [clickedRowData, setClickedRowData] = useState(null);

  // const handleDeleteRow = (row) => {
  //   axios
  //     .post('http://localhost:3006/careerdelete1row', row)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         alert('Product Deleted Successully');
  //         setSelected([]);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setOpen(null);
  // };

  // const handleDeleteSelected = (selected) => {
  //   axios
  //     .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/checkcategorypresent', selected)
  //     .then((res) => {
  //       // console.log("This is data", res.data.response[0]);
  //       // console.log("this is selected data",selected)
  //       if (res.data.response[0].category_exists === 0) {
  //         axios
  //           .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/portaldeleteselctedcategory', selected)
  //           .then((res) => {
  //             if (res.data.status === true) {
  //               alert('Category Deleted Successully');
  //               setSelected([]);
  //             } else {
  //               alert('Database Error');
  //             }
  //           })
  //           .catch((err) => console.log(err));
  //       } else {
  //         alert("Can't delete as this category has products");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  //   setSelected([]);
  // };

  const [open1, openchange] = useState(false);

  const [open2, setOpen2] = useState(false);

  const [userdata, setUserdata] = useState({
    title: '',
    category_code: '',
    rank: '',
  });

  const closepopup = () => {
    setUserdata({
      ...userdata,
      title: '',
    });
    openchange(false);
  };

  const functionopenpopup = () => {
    openchange(true);
  };

  const functionopenpopup1 = () => {
    setOpen2(true);
  };

  const AddUser = (row) => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/portaleditcategory', clickedRowData)
      .then((res) => {
        if (res.data.status === true) {
          alert('Data Edited');
        } else {
          alert("Error");
        }
      })
      .catch((err) => console.log(err));

    // console.log('This is Add User function');
    openchange(false);
    // console.log(row);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClickedRowData({
      ...clickedRowData,
      [name]: value,
    });
  };

  useEffect(() => {
    axios
      .post('https://websiteportal.rajkamalbarscan.com/api/v1/category/portalgetcategories')
      .then((res) => {
        setTest(res.data.response);
        // console.log(res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   console.log(test);
  // }, [test]);

  return (
    <>
      <Helmet>
        <title> Category List Page |RKBS </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Category List
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={Link}
            to={createPermissions ? '/dashboard/AddCategory' : '/Unauthorized'}
            sx={{ marginLeft: 'auto' }}
          >
            Add Category
          </Button>
          <div style={{ textAlign: 'center' }}>
            <Dialog open={open1} onClose={closepopup} fullWidth maxWidth="sm">
              <DialogTitle>
                EDIT Category{' '}
                <IconButton onClick={closepopup} style={{ float: 'right' }}>
                  <CloseIcon color="primary" />
                </IconButton>{' '}
              </DialogTitle>
              <DialogContent>
                <Stack spacing={2} margin={2}>
                  <TextField
                    variant="outlined"
                    label="Category Name"
                    name="title"
                    value={clickedRowData ? clickedRowData.title : userdata.title}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Category Code"
                    name="category_code"
                    value={clickedRowData ? clickedRowData.category_code : userdata.category_code}
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    label="Category Rank"
                    name="rank"
                    value={clickedRowData ? clickedRowData.rank : userdata.rank}
                    onChange={handleInputChange}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="success" variant="contained" onClick={() => AddUser(userdata)}>
                  Submit
                </Button>
                <Button onClick={closepopup} color="error" variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={null}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={null}
            selected={selected}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, rank, category_code, last_edited_on, status } = row;
                    const formattedDate = formatDate(last_edited_on);
                    const selectedUser = selected.indexOf(id) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{rank}</TableCell>
                        <TableCell align="left">{category_code}</TableCell>
                        <TableCell align="left">{formattedDate}</TableCell>
                        <TableCell align="left">
                          <Label color={(status === 'Inactive' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, row);
                              setClickedRowData(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack>
          <MenuItem onClick={editPermissions ? () => EditUser(clickedRowData) : Unauthorized}>
            {/* {console.log(clickedRowData)} */}
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => handleStatusChange(clickedRowData)}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            <Typography variant="inherit">
              {clickedRowData && clickedRowData.status === 'Inactive' ? (
                <span style={{ color: 'green' }}>Activate</span>
              ) : (
                <span style={{ color: 'red' }}>Deactivate</span>
              )}
            </Typography>
          </MenuItem>

          {/* <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteRow(clickedRowData)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Stack>
      </Popover>
    </>
  );
};

export default Category;
